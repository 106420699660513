<template>
  <div class="container">

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">
      <h4 class="my-4 text-center">
        {{ ppsInfo?.lastname }} {{ ppsInfo?.firstname }}
      </h4>

      <div class="kpi-teacher-main">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th scope="col">№</th>
            <th scope="col">Наименование</th>
            <th scope="col">Единица измерения</th>
            <th scope="col">Плановое значение</th>
            <th scope="col">Фактическое значение</th>
            <th scope="col">Балл</th>
          </tr>
          </thead>
          <tbody v-for="(kType, kTypeIndex) in kpiType" :key="kTypeIndex">

          <tr class="table-success">
            <th colspan="6">{{ kTypeIndex + 1 }}. {{ kType.name }}</th>
          </tr>

          <tr v-for="(kSubType, kSubTypeIndex) in kpiSubType.filter(i=>i.kpi_type_id===kType.id)" :key="kSubTypeIndex">
            <td>{{ kTypeIndex + 1 }}.{{ kSubTypeIndex + 1 }}</td>
            <td>{{ kSubType.name }}</td>
            <td></td>
            <td>{{ kSubType.value_plan }}</td>
            <td>{{ userKpi[kSubType.id]?.count }}</td>
            <td>{{ userKpi[kSubType.id]?.score }}</td>
          </tr>

          </tbody>
        </table>
      </div>

      <div v-if="isVisibleBtnConfirm" class="my-4 text-center">


        <div v-if="userScore">
          <div>
            Баллов {{ userScore.total_score }}
          </div>
          <div class="mt-3">
            Подтверждено
          </div>
        </div>
        <div v-else>
          <Button label="Подтвердить" @click="confirm"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "KpiTeacher",
  data() {
    return {
      loading: true,
      isVisibleBtnConfirm: false
    }
  },
  computed: {
    ...mapState('kpiTeacher', ['kpiType', 'kpiSubType', 'userKpi', 'ppsInfo', 'userScore'])
  },
  methods: {
    ...mapActions('kpiTeacher', ['GET_KPI_TYPE', 'GET_KPI_SUB_TYPE', 'POST_KPI_CONFIRM', 'GET_USER_KPI', 'GET_PPS_INFO', 'GET_CONFIRM_USER_KPI', 'GET_TYPE_PPS', 'GET_USER_SCORE']),
    // checkRole(i) {
    //   const roles = JSON.parse(getCookie('ROLES')) || []
    //   if (!roles.length && getCookie('ROLE')) {
    //     roles.push(getCookie('ROLE'))
    //   }
    //   return roles.includes(i)
    // },
    async confirm() {
      const res = await this.POST_KPI_CONFIRM()
      if (res) {
        await this.GET_USER_SCORE()
        this.$message({title: 'Подтверждено'})
      }
    }
  },
  async mounted() {
    this.isVisibleBtnConfirm = !!+(await this.GET_TYPE_PPS())

    await this.GET_USER_KPI()
    await this.GET_USER_SCORE()

    await this.GET_PPS_INFO()
    await this.GET_KPI_TYPE()
    await this.GET_KPI_SUB_TYPE()

    this.loading = false
  }
}
</script>

<style scoped>
.kpi-teacher-main{
  overflow: auto;
}
</style>
